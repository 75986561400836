import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout/"
import SEO from "../components/Seo"
import Comments from "../components/Comments"
import PostHeader from "../components/PostHeader"
import { MainContent } from "../styles/base"

export default props => {
  const post = props.data.markdownRemark

  return (
    <Layout slug={post.fields.slug}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        image={`${post.frontmatter.image}`}
      />
      <PostHeader
        date={post.frontmatter.date}
        title={post.frontmatter.title}
        timeToRead={post.timeToRead}
      />
      <MainContent>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
      </MainContent>

      <Comments url={post.fields.slug} title={post.frontmatter.title} />
    </Layout>
  )
}

export const query = graphql`
  query Post($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      fields {
        slug
      }
      frontmatter {
        date(locale: "pt-br", formatString: "DD [de] MMMM [de] YYYY")
        description
        title
      }
      timeToRead
    }
  }
`
