import styled from "styled-components"

import * as V from "../../styles/variables"

export const DateTime = styled.time`
  color: var(--secondaryColor);
  display: block;
  font-size: 0.8rem;
  &:not(:last-child) {
    margin-bottom: ${V.Space.xxs};
  }
`
