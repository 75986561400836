import styled from "styled-components"
import media from "styled-media-query"

export const PostHeader = styled.h1`
  margin: auto;
  max-width: 70rem;
  padding: 2rem 1rem 1rem 1rem;

  ${media.lessThan("large")`
    padding: 3rem 1rem;
    max-width: 100%;
  `}
`

export const PostTitle = styled.h1`
  font-size: 1.8rem;
  font-weight: 800;
  padding: 0 1.4rem;
  margin: 1rem auto;

  &.welcome {
    font-size: 2.8rem;
    font-weight: 800;
    line-height: 4rem;
    margin-bottom: 0.6em;
  }

  ${media.lessThan("large")`
    font-size: 1.8rem;
    line-height: 1.1;
    padding: 0;
  `}
`

export const PostDescription = styled.h2`
  font-size: 1.6rem;
  font-weight: 200;
  padding: 0 1.4rem;

  ${media.lessThan("large")`
    font-size: 1.6rem;
    line-height: 1.3;
    padding: 0;
  `}
`

export const PostDate = styled.p`
  font-size: 1.25rem;
  font-weight: 100;
  padding: 0 1.4rem;


  ${media.lessThan("large")`
    padding: 0 1rem;
  `}
`

export const MainContent = styled.section`
  text-rendering: optimizeLegibility;
  margin: 0;
  max-width: 100%;
  padding: 2rem 0 1rem 0;

  ${media.lessThan("large")`
    padding: 1rem 0;
    max-width: 100%;
  `}

  p,
  h1,
  h2,
  h3,
  h4,
  ul,
  ol,
  .tags,
  iframe,
  .button-post {
    color: var(--texts);
    font-size: 1.1rem;
    font-weight: 300;
    line-height: 1.5;
    letter-spacing: 0;
    padding: 0;
    margin : 0;

    ${media.lessThan("large")`
      padding: 0.2rem 0;
      word-break: break-word;
    `}
  }

  p {
    margin: 0 0 1.6rem 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 1rem auto;
  }

  ul,
  ol {
    list-style: disc;
    padding-left: 2.5rem;
    margin: 0 auto 1.6rem;
  }

  li {
    padding: 0.625rem 0;

    & > ul {
      margin-bottom: 0;
    }
  }

  p,
  li {
    code {
      word-wrap: break-word;
    }
  }

  img {
    display: block;
    max-width: 100%;
    margin: 1.875rem auto;
  }

  iframe {
    padding: 0;
    margin: 0;
    border:0;
    width: 100%;

  }

  blockquote {
    color: var(--postColor);
    border-left: 0.3rem solid var(--highlight);
    padding: 0;
    margin: 3.125rem auto;
    p {
      font-weight: 300;
      color: #666;
      padding : 0 4rem;
      font-style: italic;
      ${media.lessThan("large")`
        font-style: normal;
        padding: 0 1rem;
      `}
    }
  }

  hr {
    border: 1px solid var(--borders);
    margin: 3rem auto;
  }

  #twitter-widget-0,
  .instagram-media,
  .twitter-tweet {
    margin: 20px auto !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.5rem;
  }

  h1 {
    font-size: 2.5rem;
    font-weight: 400;

    ${media.lessThan("large")`
      font-size: 1.8rem;
    `}
  }

  h2 {
    font-size: 1.8rem;

    ${media.lessThan("large")`
      font-size: 1.3rem;
    `}
  }

  h3 {
    font-size: 1.5rem;

    ${media.lessThan("large")`
      font-size: 1.125rem;
    `}
  }

  h4 {
    font-size: 1.4rem;
  }

  h5 {
    font-size: 1.2rem;
  }

  strong {
    font-weight: 700;
  }

  .gatsby-resp-image-background-image {
    z-index: 2;
    opacity: 1 !important;
  }

  .gatsby-resp-image-image {
    box-shadow: none !important;
    transition: opacity 0.2s;

    &.lazyload {
      opacity: 0;
    }

    &.lazyloaded {
      opacity: 1;
      z-index: 3;
    }
  }

  .gatsby-highlight {
    padding: 0 1.6rem 1.6rem;

    ${media.lessThan("large")`
      padding: 0;
    `}
  }

  .instagram-media {
    margin: 1rem auto !important;
  }

  a {
    border-bottom: 1px dashed var(--highlight);
    color: var(--highlight);
    text-decoration: none;
    transition: opacity 0.5s;

    svg {
      color: var(--postColor);
    }

    &:hover {
      opacity: 0.8;
    }
  }
`
