import React from "react"
import PropTypes from "prop-types"

import DateTime from "../DateTime"
import ButtonLink from "../ButtonLink"

import * as S from "./styled"

const PostHeader = ({ image, date, timeToRead, title, description }) => {
  return (
    <S.PostHeader>
      <S.ButtonBack>
        <ButtonLink rel="prev" to="/blog">
          {'«'} voltar
        </ButtonLink>
      </S.ButtonBack>
      <S.Title>{title}</S.Title>
      <DateTime>
        <span itemProp="datePublished">{date}</span>
        {timeToRead && <span> · Leitura de {timeToRead} min</span>}
      </DateTime>
    </S.PostHeader>
  )
}

PostHeader.propTypes = {
  date: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  tags: PropTypes.array.isRequired,
  image: PropTypes.object,
  timeToRead: PropTypes.number,
}

export default PostHeader
