import styled from "styled-components"

import * as V from "../../styles/variables"
import * as T from "../../styles/typography"

export const PostHeader = styled.header`
  margin-top: ${V.Space.xs};
  &:not(:last-child) {
    margin-bottom: 0;
  }
`

export const ButtonBack = styled.div`
  &:not(:first-child) {
    margin-top: ${V.Space.default};
  }

  &:not(:last-child) {
    margin-bottom: ${V.Space.xs};
  }
`
export const Title = styled.h1.attrs({
  itemProp: "name",
})`
  ${T.Title}


  &:not(:first-child) {
    margin-top: ${V.Space.xs};
  }

  &:not(:last-child) {
    margin-bottom: ${V.Space.xs};
  }
`

export const Subtitle = styled.h2.attrs({
  itemProp: "description",
})`
  ${T.Subtitle1}

  &:not(:last-child) {
    margin-bottom: ${V.Space.default};
  }
`
export const Author = styled.h3.attrs({
  itemProp: "author",
})`

`
