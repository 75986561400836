import styled from "styled-components"
import { Link } from "gatsby"

import * as T from "../../styles/typography"

export const ButtonLink = styled(Link)`
  ${T.Link}
  display: inline-block;
  padding: 5px 10px;
  font-size: 1rem;
  font-weight: 300;
  text-decoration: none;
  background: #f4f4f4;
  border: 1px solid #efefef;
  border-radius: 5px;
`
